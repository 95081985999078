import { Grid, Input, MenuItem, Typography as MuiTypography, Select, TextField } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { camelCase } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconButton } from "..";
import "./EnhancedTable.css";

// const MAX_NBR_VALUES_FOR_SELECT = 20;

const Typography = styled(MuiTypography)(spacing);

export default function AdvancedFilter({ rows, cols, self, advancedFilters, setAdvancedFilters, first }) {
	const { t } = useTranslation();

	const [settings, setSettings] = useState(self); // On garde une copie locale des paramètres du filtre
	function setSelf(value) {
		setSettings(value);

		// On met à jours la liste des filtres, en remplaçant celui actuel avec "settings" qui est le state du composant
		setAdvancedFilters([...advancedFilters.map((advancedFilter) => (advancedFilter.id === self.id ? settings : advancedFilter))]);
	}

	// #region Fonction de handle
	function handleChangeKey(event) {
		settings.key = event.target.value;
		settings.value = ""; // On reset la valeur

		const col = cols.find((col) => col.id === settings.key);

		if (!col || !rows) {
			return;
		}

		settings.type = col?.type;

		if (settings?.type === "bool") {
			settings.valueOptions = ["false", "true"];
			setSelf(settings);
			return;
		}

		// Si la valeur de l'option est un tableau, on check les valeurs unique des options
		if (settings?.type === "array") {
			let allValues = [];
			rows.forEach((row) => row[event.target.value].forEach((option) => allValues.push(option)));
			settings.valueOptions = Array.from(new Set(allValues));
		} else {
			// Construction d'une liste de valeur unique à afficher pour le select
			const availableValues = new Set([...rows.filter((row) => !!row[event.target.value]).map((row) => row[event.target.value].toString())]);
			settings.valueOptions = Array.from(availableValues);
		}

		setSelf(settings);
	}

	function handleChangeValue(event, value) {
		settings.value = value;
		setSelf(settings);
	}

	function handleDelete(id) {
		setAdvancedFilters([...advancedFilters.filter((filter) => filter.id !== id)]);
	}
	// #endregion Fonction de handle

	return (
		<Grid item xs>
			<Typography display="inline" mr={2}>
				{first ? "Où" : "et"}
			</Typography>

			<Select value={settings.key} onChange={handleChangeKey} autoFocus>
				{cols.map((col) => (
					<MenuItem key={col.id} value={col.id}>
						{t(camelCase(col?.label || col.id))}
					</MenuItem>
				))}
			</Select>

			<Typography display="inline" mx={2}>
				{"est"}
			</Typography>

			{/* // todo : Appliquer le filtre à l'appui sur tab ou entré */}
			{settings.valueOptions.length > 0 ? (
				<Autocomplete
					value={settings.value}
					options={settings.valueOptions}
					onChange={handleChangeValue}
					renderInput={(params) => <TextField {...params} display="inline" size="small" variant="standard" autoFocus fullWidth />}
					renderOption={(option) => <Typography noWrap>{option}</Typography>}
					disableListWrap
				></Autocomplete>
			) : (
				<Input></Input>
			)}

			<IconButton
				icon={<Delete />}
				title={t("translation:delete")}
				onClick={() => handleDelete(settings.id)}
				tabIndex="-1"
				style={{ color: red[800] }}
				size="small"
			></IconButton>
		</Grid>
	);
}
